import { gql } from 'graphql-request';

export const GET_OPEN_TICKETS = gql`
  query ListCases($locationNum: String!, $contactGuid: String, $page: Int) {
    openCases(
      locationNum: $locationNum
      contactGuid: $contactGuid
      page: $page
    ) {
      pageInfo {
        totalItems
        totalPages
        hasNextPage
      }
      cases {
        id
        number
        status
        description
        contact {
          audience
          fullName
        }
        assignedTo {
          firstName
          audience
          photoURL
          fullName
        }
        updatedOn
        isUnread
        locationNum
        canRequestUpdate
        confidential
      }
    }
  }
`;

export const GET_OPEN_TICKETS_BY_USER = gql`
  query ListCases($locationNum: String!, $user: String, $page: Int) {
    openCases(locationNum: $locationNum, contactGuid: $user, page: $page) {
      pageInfo {
        totalItems
        totalPages
        hasNextPage
      }
      cases {
        id
        number
        status
        description
        contact {
          audience
          fullName
        }
        assignedTo {
          firstName
          audience
          photoURL
          fullName
        }
        updatedOn
        isUnread
        locationNum
        canRequestUpdate
        confidential
      }
    }
  }
`;

export const GET_CLOSED_TICKETS = gql`
  query ListCases($locationNum: String!, $page: Int) {
    closedCases(locationNum: $locationNum, page: $page) {
      pageInfo {
        totalItems
        totalPages
        hasNextPage
      }
      cases {
        id
        number
        status
        description
        contact {
          audience
          fullName
        }
        assignedTo {
          firstName
          audience
          photoURL
          fullName
        }
        updatedOn
        isUnread
        locationNum
        canRequestUpdate
        confidential
      }
    }
  }
`;

export const GET_ALL_TICKETS = gql`
  query ListCases($locationNum: String!, $page: Int) {
    cases(locationNum: $locationNum, page: $page) {
      pageInfo {
        totalItems
        totalPages
        hasNextPage
      }
      cases {
        id
        number
        status
        description
        contact {
          audience
          fullName
        }
        assignedTo {
          firstName
          audience
          photoURL
          fullName
        }
        updatedOn
        isUnread
        locationNum
        canRequestUpdate
        confidential
      }
    }
  }
`;

export const GET_TICKET_LOCATION_BY_ID = gql`
  query GetCase($id: ID!, $markRead: Boolean) {
    case(id: $id, markRead: $markRead) {
      locationNum
    }
  }
`;

export const GET_TICKET_BY_ID = gql`
  query GetCase($id: ID!, $markRead: Boolean) {
    case(id: $id, markRead: $markRead) {
      id
      number
      status
      description
      longDescription
      isUnread
      promptTypes
      confidential
      description
      longDescription
      closedOn
      willCloseOn
      openedOn
      canRequestUpdate
      assignedTo {
        firstName
        lastName
        fullName
        guid
        audience
        photoURL
      }
      contact {
        firstName
        lastName
        fullName
        guid
      }
      openedOn
      locationNum
      isUnread
      promptTypes
      assignedTo {
        id
        firstName
        lastName
        guid
        audience
        photoURL
      }
      contact {
        firstName
        lastName
        fullName
        guid
      }
      requestedItem {
        number
        id
      }
      submissionDetails {
        value
        label
      }
      activityStream {
        __typename
        id
        caseId
        text
        createdBy {
          __typename
          id
          firstName
          lastName
          fullName
          guid
          audience
          photoURL
        }
        createdOn
        ... on ActivityStreamAttachment {
          downloadURL
          fileName
          mimeType
        }
        ... on ActivityStreamComment {
          translatedText
        }
      }
    }
  }
`;

export const GET_LOCATION = gql`
  query getLocation($locationNum: String!) {
    restaurant(locationNum: $locationNum) {
      id
      number
      name
      supportDeskPhoneNumber
      siteProperties {
        lat
        long
        countryCode
      }
      operator {
        fullName
        photoURL
        email
      }
      contacts {
        id
        guid
        firstName
        fullName
        lastName
        audience
        photoURL
      }
    }
  }
`;

export const GET_STAFF_LOCATIONS = gql`
  query StaffLocations($guid: String!) {
    restaurantsStaff(userGuid: $guid) {
      type
      restaurants {
        number
        name
        openCasesCount
      }
    }
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query MyQuery {
    restaurants {
      number
      name
    }
  }
`;

export const GET_CLOSE_REASONS = gql`
  query GetKBFeedbackReasons {
    closeCaseReasons {
      label
      value
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query GetAppointments($locationNums: [String]) {
    appointments(locationNums: $locationNums) {
      id
      dueDate
      subject
      status
      restaurant {
        number
        name
        siteProperties {
          lat
          long
          utcOffset
          tzId
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_DETAILS = gql`
  query GetAppointment($id: ID!) {
    appointment(id: $id) {
      id
      dueDate
      subject
      description
      contact
      status
      caseNumber
      technician {
        id
        firstName
        lastName
        fullName
        guid
        audience
        photoURL
        photo
        title
        mobilePhone
      }
      restaurant {
        number
        name
        siteProperties {
          lat
          long
          utcOffset
          tzId
        }
      }
    }
  }
`;

export const GET_ANNOUNCEMENTS = gql`
  query GetAnnouncements {
    announcements {
      category
      id
      summary
      title
      updatedOn
      hasBeenUpdated
      url
      majorCaseId
      isExpired
      outagePageURL
    }
  }
`;
export const GET_SUPPORT_CATEGORIES = gql`
  query GetSupportCategories {
    supportCategories {
      id
      label
      imageName
      url
      items {
        id
        featuredOrder
        recentlyFeaturedOrder
        commonlyUsedOrder
        recommendedOrder
        label
        description
        imageName
        url
        form {
          id
          minimumAppVersion
          introContents {
            header
            body {
              text
              imageName
            }
          }
          showIntroOnLoad
        }
        items {
          id
          featuredOrder
          recentlyFeaturedOrder
          commonlyUsedOrder
          recommendedOrder
          label
          description
          imageName
          url
          form {
            id
            minimumAppVersion
            introContents {
              header
              body {
                text
                imageName
              }
            }
            showIntroOnLoad
          }
        }
      }
    }
  }
`;

export const GET_SUPPORT_CATEGORY_ITEM = gql`
  query GetSupportCategoryItem($id: ID!) {
    supportCategoryItem(id: $id) {
      id
      label
      description
      imageName
      url
      form {
        id
        minimumAppVersion
        introContents {
          header
          body {
            text
            imageName
          }
        }
        showIntroOnLoad
      }
      featuredOrder
      commonlyUsedOrder
      recentlyFeaturedOrder
      recommendedOrder
    }
  }
`;

export const GET_FORM_LAYOUT = gql`
  query GetFormLayout(
    $formId: ID!
    $locationNum: String
    $defaults: [FormVariable]
  ) {
    formLayout(id: $formId, locationNum: $locationNum, defaults: $defaults) {
      id
      version
      title
      usesAttachments
      layout {
        id
        title
        header
        footer
        groups {
          id
          header
          footer
          fields {
            id
            fieldType
            visible
            align
            header
            footer
            footerEmphasized
            styles {
              label
              supportingLabels
              value
              filterSets
              matches
              hiddenMatches
            }
            variesBasedOn
            variants {
              key
              changes {
                header
                visible
                disabled
                required
                text
                nextStage
                content
                input {
                  current {
                    label
                    supportingLabels
                    value
                    filterSets
                    matches
                    hiddenMatches
                  }
                  options {
                    label
                    supportingLabels
                    value
                    filterSets
                    matches
                    hiddenMatches
                  }
                }
              }
            }
            ... on FormLayoutReadOnlyField {
              text
            }
            ... on FormLayoutTableField {
              content
            }
            ... on FormLayoutInputField {
              submissionField
              alternateSubmissionField
              disabled
              required
              inputProperties {
                current {
                  label
                  supportingLabels
                  value
                  filterSets
                  matches
                  hiddenMatches
                }
                ... on FormLayoutInputFieldInputSelect {
                  options {
                    label
                    supportingLabels
                    value
                    filterSets
                    matches
                    hiddenMatches
                  }
                  interactionType
                  namedSupportingText
                  genericSupportingText
                  manualEntryRoute
                  filters {
                    label
                    subLabel
                    keyword
                    sortStrategy
                  }
                  sortStrategy
                }
                ... on FormLayoutInputFieldInputDate {
                  ruleSet
                  defaultDate
                  startDate
                  endDate
                }
                ... on FormLayoutInputFieldInputTime {
                  ruleSet
                  format
                }
                ... on FormLayoutInputFieldInputPhone {
                  placeholderText
                }
                ... on FormLayoutInputFieldInputText {
                  placeholderText
                  maxLines
                  maxCharacters
                  minCharacters
                  hideMaxCharactersCount
                  keyboard
                }
                ... on FormLayoutInputFieldInputTextTypeahead {
                  placeholderText
                  maxLines
                  maxCharacters
                  query
                }
              }
            }
            ... on FormLayoutInteractionField {
              usageInstructions
              required
            }
          }
          styles {
            label
            supportingLabels
            value
            filterSets
            matches
            hiddenMatches
          }
          submissionFields
          orientation
          required
        }
        styles {
          label
          supportingLabels
          value
          filterSets
          matches
          hiddenMatches
        }
        submissionFields
        required
        proceedOnAnyRequired
        supportingData
        nextStage
        variesBasedOn
        variants {
          key
          changes {
            header
            visible
            disabled
            required
            text
            nextStage
            content
            input {
              current {
                label
                supportingLabels
                value
                filterSets
                matches
                hiddenMatches
              }
              options {
                label
                supportingLabels
                value
                filterSets
                matches
                hiddenMatches
              }
            }
          }
        }
        asides {
          id
          header
          footer
          groups {
            id
            header
            footer
            fields {
              id
              fieldType
              visible
              align
              header
              footer
              footerEmphasized
              styles {
                label
                supportingLabels
                value
                filterSets
                matches
                hiddenMatches
              }
              variesBasedOn
              variants {
                key
                changes {
                  header
                  visible
                  disabled
                  required
                  text
                  nextStage
                  content
                }
              }
              ... on FormLayoutReadOnlyField {
                text
              }
              ... on FormLayoutTableField {
                content
              }
              ... on FormLayoutInputField {
                submissionField
                alternateSubmissionField
                disabled
                required
                inputProperties {
                  current {
                    label
                    supportingLabels
                    value
                    filterSets
                    matches
                    hiddenMatches
                  }
                  ... on FormLayoutInputFieldInputSelect {
                    options {
                      label
                      supportingLabels
                      value
                      filterSets
                      matches
                      hiddenMatches
                    }
                    interactionType
                    namedSupportingText
                    genericSupportingText
                    manualEntryRoute
                    filters {
                      label
                      subLabel
                      keyword
                      sortStrategy
                    }
                    sortStrategy
                  }
                  ... on FormLayoutInputFieldInputDate {
                    ruleSet
                  }
                  ... on FormLayoutInputFieldInputTime {
                    ruleSet
                    format
                  }
                  ... on FormLayoutInputFieldInputPhone {
                    placeholderText
                  }
                  ... on FormLayoutInputFieldInputText {
                    placeholderText
                    maxLines
                    maxCharacters
                    hideMaxCharactersCount
                    keyboard
                  }
                  ... on FormLayoutInputFieldInputTextTypeahead {
                    placeholderText
                    maxLines
                    maxCharacters
                    query
                  }
                }
              }
              ... on FormLayoutInteractionField {
                usageInstructions
                required
              }
            }
            styles {
              label
              supportingLabels
              value
              filterSets
              matches
              hiddenMatches
            }
            submissionFields
            orientation
            required
          }
          styles {
            label
            supportingLabels
            value
            filterSets
            matches
            hiddenMatches
          }
        }
      }
      variables {
        id
        value
        displayValue
        description
        sensitive
      }
    }
  }
`;

export const SEARCH_ALL_SUPPORT = gql`
  query Search(
    $contains: String!
    $context: [SupportSearchContext]
    $ignoreTrackSearch: Boolean
  ) {
    searchAllSupport(
      contains: $contains
      context: $context
      ignoreTrackSearch: $ignoreTrackSearch
    ) {
      articles {
        id
        number
        title
        solutionContent
        searchQueryId
        searchResultId
      }
      forms {
        id
        label
        description
        imageName
        url
        form {
          id
          minimumAppVersion
          introContents {
            header
            body {
              text
              imageName
            }
          }
          showIntroOnLoad
        }
      }
      searchResultCases {
        id
        number
        status
        description
        updatedOn
        confidential
      }
    }
  }
`;

export const GET_SECURE_SNOW_URL = gql`
  query GetNewServiceNowLink($link: String!) {
    secureServiceNowLink(unsecureLink: $link)
  }
`;

export const GET_RELATED_MINOR_CASES = gql`
  query ListRelatedCases($caseId: ID!) {
    relatedCases(majorCaseId: $caseId) {
      id
      number
      ... on MinorCaseType {
        locationNum
      }
    }
  }
`;

export const GET_KB_ARTICLE = gql`
  query GetKBArticle(
    $id: ID!
    $trackView: Boolean
    $foundViaQueryId: ID
    $foundViaResultId: ID
  ) {
    kbArticle(
      id: $id
      trackView: $trackView
      foundViaQueryId: $foundViaQueryId
      foundViaResultId: $foundViaResultId
    ) {
      id
      number
      title
      solutionContent
    }
  }
`;

export const GET_RECENT_SEARCHES = gql`
  query GetRecentSearches($userGuid: String!) {
    searchesRecent(userGuid: $userGuid)
  }
`;

export const GET_HELPFUL_RECENT_KB_ARTICLES = gql`
  query GetKBArticles {
    kbArticlesAnswers {
      articlesFeatured {
        id
        number
        title
      }
      articlesRecent {
        id
        number
        title
      }
    }
  }
`;

export const GET_KB_FEEDBACK_REASONS = gql`
  query GetKBFeedbackReasons {
    kbFeedbackReasons {
      label
      value
    }
  }
`;

export const GET_ASSETS = gql`
  query GetAssets($locationNums: [String]!, $csiCode: String) {
    assets(locationNums: $locationNums, csiCode: $csiCode) {
      id
      tag
      serial
      csiCode
      name
      locationNum
      department
      type {
        id
        name
        family
      }
      model {
        id
        imageURL
        allowAdditionalEquipmentRequest
      }
      manufacturer
      relatedCases {
        id
        number
        ... on AssetCaseType {
          description
        }
      }
    }
  }
`;

export const GET_RECENT_ASSETS = gql`
  query MyQuery($guid: String!) {
    assetsRecent(userGuid: $guid) {
      id
      tag
      serial
      locationNum
      viewedOn
    }
  }
`;

export const GET_ASSET_RESOURCES = gql`
  query GetResources($locationNum: String!) {
    assetResources(locationNum: $locationNum) {
      key
      externalURL
      imageName
      label
    }
  }
`;

export const GET_SUPPORT_CATEGORIES_ASSET = gql`
  query GetSupportCategories($context: SupportCategoryContext) {
    supportCategoriesAsset(context: $context) {
      id
      label
      description
      imageName
      url
      form {
        id
        minimumAppVersion
        introContents {
          header
          body {
            text
            imageName
          }
        }
        showIntroOnLoad
        defaults {
          id
          value
        }
      }
    }
  }
`;

export const GET_AIRSHIP_USER_PROPERTIES = gql`
  query GetAirshipUserProperties($guid: String) {
    airshipUserProperties(namedUserGuid: $guid) {
      attributes {
        label
        value
      }
      tagGroups {
        name
        tags
      }
    }
  }
`;

export const GET_CASE_PROMPTS = gql`
  query MyQuery($caseId: ID!, $promptTypes: [CasePromptType]) {
    casePrompts(caseId: $caseId, promptTypes: $promptTypes) {
      __typename
      id
      promptType
      title
      description
      ... on CasePromptViewAppointment {
        appointmentId
      }
    }
  }
`;

export const GET_ASSET_WARRANTIES = gql`
  query MyQuery($assetId: ID!) {
    assetWarranties(assetId: $assetId) {
      id
      type
      name
      status
      expiresOn
      termMonths
      coverage {
        header
        body
      }
    }
  }
`;

export const GET_USER_EVENT_INFO = gql`
  query GetUserEventInfo($eventType: String!, $filter: UserEventFilter) {
    userEventInfo(eventType: $eventType, filter: $filter) {
      userGuid
      eventType
      count
    }
  }
`;
